.chat-input-wrapper{
    width: 100%;
    height: 68px;
    display: flex;
    justify-content: center;
    align-items: center;
    justify-content: space-between;
    padding: 0px 10px 20px 10px;
}
.chat-input-second-wrapper{
    width: 100%;
    height: 100%;
    background-color: #40444B;
    display: flex;
    border-radius: 10px;
    padding-left: 16px;

}
.chat-input-right-button-wrapper{
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.chat-input-right-button-wrapper svg{
    color: #b9bbbe;

}

.chat-input-right-button-wrapper div{
    height: 100%;
    margin-right: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.chat-input{
    display: flex;
    flex: 1 auto;
    height: 100%;
}
.chat-input input{
    width: 100%;
    height: 100%;
    border: none;
    background-color: transparent;
    color: #fff;
    font-size: 16px;
    padding: 0 10px;
    outline: none;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}


.chat-body-wrapper{
    display: flex;
    flex: 1;
    flex-direction: column;
    overflow-y:scroll;
    overflow-x: hidden; 
    margin: 4px;
    margin-top: 4px;
    margin-bottom: 4px;
}
.chat-body-wrapper::-webkit-scrollbar {
    width: 8px;
    background-color: #2e3338;
    border-radius: 5px;

}
.chat-body-wrapper::-webkit-scrollbar-track {
    border-radius: 10px;
}
   
.chat-body-wrapper::-webkit-scrollbar-thumb {
    background: #202225; 
    border-radius: 10px;
}

.chat-body-message{
    display: flex;
    flex-direction: row;
    padding: 10px;
    margin-bottom: 10px;
}
.chat-body-message:hover{
    background-color: #32353B;
}
.chat-body-message-text{
    margin-left: 10px;
    display: flex;
    flex-direction: column;
}
.chat-body-message-text-name{
    font-size: 15px;
    color: #fff;
    font-weight: 500;
    cursor: pointer;
}
.chat-body-message-text-name:hover{
    text-decoration: underline;
}
.chat-body-message-text-msg{
    font-size: 14px;
    color: #B9BBBA;
    color:var(--deneme)
}
