.auth-wrapper{
    width: 100%;
    height: 100%;
    overflow: hidden;
    overflow-y: scroll;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url(../img/background.svg);
    -ms-overflow-style: none;  
    scrollbar-width: none;
}
.auth-wrapper::-webkit-scrollbar {
    display: none;
  }

.auth-input-wrapper{
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-top: 20px;
}

.auth-email-wrapper{
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-top: 20px;
}

.auth-password-wrapper{
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.auth-input{
    background-color: #202225;
    color: white;
    padding: 8px;
    border-radius: 5px;
    border: none;
    width: 100%;
    outline: none;
}

.login-wrapper{
    background-color: #36393F;
    color: white;
    padding: 32px;
    width: 784px;
    display: flex;
    flex-direction: row;
    border-radius: 5px;
}

.auth-header-wrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-weight: 600;
    font-size: 24px;
    line-height: 30px;
    font-family: Ginto,"Helvetica Neue",Helvetica,Arial,sans-serif;
}

.auth-button{
    background-color: hsl(235, 85.6%,64.7%);
    color: white;
    padding: 2px 16px;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    margin-top: 16px;
    width: 100%;
    height: 44px;
}

.auth-button:hover{
    background-color: #4752C4;

}

.auth-links-button{
    color: hsl(197,100%,47.8%);
    border: none;
    cursor: pointer;
    margin-top: 5px;
    background: none;
    outline: none;
    cursor: pointer;
}

.auth-links-button:hover{
    text-decoration: underline;
}

.login-qr-wrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    min-width: 240px;
    height: 344px;
    text-align: center;
}

.login-qr{
    width: 176px;
    height: 176px;
    background-color: #202225;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.login-center-wrapper{
    display:"flex";
    justify-content:"center";
    align-items:"center"
}

.register-wrapper{
    min-height: 580px;
    max-width: 480px;
    background: #36393F;
    display: flex;
    flex-direction: column;
    padding: 32px;
    color: white;
}

.register-date-wrapper{
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-top: 20px;
}

.register-date{
    display: flex;
    flex-direction: row;
    
}

.register-date-input-wrapper{
    display: flex;
    justify-content: center;
    margin-top: 20px;
}
.register-date input{
    background-color: rgba(0, 0, 0, 0.1);
    /* border: 1px solid rgba(32, 34, 37, 0.5); */
    width: 100%;
    height: 40px;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    outline: none;
    color: white;
    border-radius: 4px;
    border-color: rgba(32, 34, 37, 0.5);
    border-style:solid;
    border-width:1px;
}

.register-date :nth-child(2){
    margin-inline: 20px;
}

.register-term-wrapper{
    display: flex;
    margin-top: 20px;
    align-items: center;
}

.register-term-text{
    color: #a3a6aa;
    font-size: 12px;
    margin-bottom: 0;
}

.register-term-checkbox{
    margin-right: 10px;
    height: 24px;
    width: 24px;
    accent-color: hsl(235,85.6%,64.7%);
    
}
@media only screen and (max-width: 485px) {
    .login-wrapper{
        width: 100%;
        height: 100%;
        display: block;
        border-radius: 0;
    }
    .auth-header-wrapper{
        padding-top: 15px ;
    }
    .auth-header-wrapper::before{
        background: url(../img/logo-2.svg) !important;
        content: "";
        position: absolute;
        height: 36px;
        top: 22px;
        width: 130px;
    }

}

@media only screen and (min-width: 486px) {
    /* .login-wrapper{
        min-height:580px
    } */

}

@media only screen and (max-width: 830px) {
    .login-qr-wrapper{
        display: none !important;
    }
    .login-center-wrapper{
        display: none !important;
    }
    .login{
        max-width: 480px;
    }
}