.dashboard-wrapper{
    display: flex;
    overflow: hidden;
    flex-direction: column;
    flex: 1;
}
.dashboard-second-wrapper{
    display: flex;
    height: 100%;
}
.dashboard-sidebar{
    width: 240px;
    background-color: #2F3136;
    height: 100%;
    display: flex;
    flex-direction: column;
}
.dashboard-panel{
    width: 100%;
    height: 100%;
    background-color: #36393F;
    display: flex;
    flex-direction: column;
}

.dashboard-side-navbar{
    overflow: hidden;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}

.dashboard-panel-header-wrapper{
    position: relative;
    width: 100% ;
    height: 48px;
    box-shadow: 0 1px 0 rgb(4 4 5 / 20%), 0 1.5px 0 rgb(6 6 7 / 5%), 0 2px 0 rgb(4 4 5 / 5%);
    display: flex;
    align-items: center;
    overflow: hidden;
    margin-right: 5px;
    padding: 10px;
}

.dashboard-panel-header-wrapper svg{
    color: #DCDDDE;
    cursor: pointer;

}
.dashboard-panel-header-left{
    display: flex;
}

.dashboard-panel-header-left svg{
    color:#72767D;
    margin: 0 8px;
}
.dashboard-panel-header-left span{
    color:#fff;

}

.dashboard-panel-header-line{
    height: 24px;
    width: 1px;
    background-color: #72767D;
    margin: 0 15px;
}

.dashboard-panel-header-text{
    color:#72767D;
    font-size: 15px;
    font-weight: 500;
    margin-right:15px;
    padding: 1px 5px;
    cursor: pointer;
    border-radius: 5px;
}
.dashboard-panel-header-text:hover{
    background: #3A3E44;
    color: #ccc;
}
.dashboard-panel-header-text-active{
    background: rgba(79,84,92,0.32);
    color:#eee;
    font-size: 15px;
    font-weight: 500;
    margin-right:15px;
    padding: 1px 5px;
    cursor: pointer;
    border-radius: 5px;
}
.dashboard-panel-header-text-active:hover{
    background: #3A3E44;
}
.dashboard-panel-header-button{
    display: flex;
    align-items: center;
    padding: 1px 5px;
    border-radius: 5px;
    background-color: #3BA55D;
    color: #fff;
    font-size: 15px;
    font-weight: 500;
    cursor: pointer;
}
.dashboard-panel-header-button-active{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    border-radius: 5px;
    color: #3BA55D;
    font-size: 15px;
    font-weight: 500;
    cursor: pointer;
}
.dashboard-panel-header-button-active:hover{
    background-color: #3A3E44;

}
.dashboard-sidebar-section-avatar{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    background-color: #E64043;
    border-radius: 50%;
    margin-right: 10px;
}
.dashboard-sidebar-section-avatar-state{
    position: absolute;
    bottom: 0px;
    right: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 10px;
    height: 10px;
    background-color: #3BA55D;
    border-radius: 50%;
    margin-left: 5px;
    box-shadow: 0px 0px 0px 2.5px #292B2F;    
}
.dashboard-sidebar-section-wrapper{
    width: 100%;
    height: 52px;
    background-color: #292B2F;
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 0px 10px;
    flex: 0 0 auto;
}

.dashboard-sidebar-section-user{
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex:auto;

}
.dashboard-sidebar-section-user-name{
    height: 18px;
    font-size: 15px;
    color: white;
    display: flex;
    padding: 0;
    align-items: flex-end;
}
.dashboard-sidebar-section-user-code{
    height: 13px;
    font-size: 12px;
    color: #ccc;
    padding: 0;
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
}
.dashboard-sidebar-section-buttons{
    display: flex;
    align-items: center;
    justify-content: center;
}
.dashboard-sidebar-section-buttons button{
    border:  none;
    background: none;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    margin-right: 5px;
    cursor: pointer;
    
}
.dashboard-sidebar-section-buttons button svg{
    color: #72767D;
}

.dashboard-panel-header-absolute-button{
    position: absolute;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2px;
    border-radius: 5px;
    background-color: #36393F;
    font-size: 15px;
    font-weight: 500;
    cursor: pointer;
}
.dashboard-panel-header-absolute-button * svg{
    color: #b9bbbe;
    margin-right: 15px;

}

.dashboard-sidebar-chat-wrapper{
    padding: 2px 5px;
    -ms-overflow-style: none;
    scrollbar-width: none;  
    flex: 1 1 auto;
    overflow-y: auto;
    min-height: 0px;
    height: 100%;
  
}
.dashboard-sidebar-chat-wrapper::-webkit-scrollbar {
    display: none;
}

.dashboard-sidebar-chat-buttons-wrapper{
    margin-top:8px;
    width: 100%;
    height: 88px;
    color: #72767D;
    padding: 0px 5px;
    justify-content: space-between;
    display: flex;
    flex-direction: column;
}
.dashboard-sidebar-chat-button{
    padding: 5px 7px;
    display: flex;
    align-items: center;
    height: 49%;
    border-radius: 5px;
    cursor: pointer;
}
.dashboard-sidebar-chat-button-active{
    padding: 5px 7px;
    display: flex;
    align-items: center;
    height: 49%;
    border-radius: 5px;
    cursor: pointer;
    background-color: #393C43;
    color: #fff;
}

.dashboard-sidebar-chat-button:hover{
    background-color: #34373C;
    color: #fff;
}

.dashboard-sidebar-chat-wrapper svg{
    fill: #36393F;
}

.dashboard-sidebar-chat-header-wrapper{
    display: flex;
    width: 100%;
    padding: 0px 10px;
    justify-content: space-between;
    margin-top: 10px;

}
.dashboard-sidebar-chat-header-wrapper:hover .dashboard-sidebar-chat-header{
    color: #dcddde;
}
.dashboard-sidebar-chat-header{
    font-size: 12px;
    color: #72767D;
    font-weight: bold;
}
.dashboard-sidebar-chat-header-wrapper svg{
    height: 17px;
    width: 17px;
    cursor: pointer;
    color: #b9bbbe;
}
.dashboard-sidebar-chat-buttons-wrapper svg{
    width: 24px;
    height: 24px;
    margin-right:10px ;
}
.dashboard-sidebar-chat{
    margin-top: 20px;
    width: 100%;
    color: #72767D;
    padding: 0px 10px;
    font-size: 13px;
    display:flex;
    flex-direction: column;
}
.dashboard-sidebar-chat-item-wrapper{
    display: flex;
    flex-direction: row;
    padding: 5px 0px;
    cursor: pointer;
    border-radius: 5px;
    padding: 5px 10px;

}
.dashboard-sidebar-chat-item-name{
    font-size: 14px;
    font-weight: 600;
}
.dashboard-sidebar-chat-item-wrapper:hover{
    background-color: #3A3E44;
}
.dashboard-panel-body-wrapper{
    display: flex;
    flex: 1;
    flex-direction: column;
    overflow-y: auto;
}

.dashboard-panel-nowplaying-header{
    color: #fff;
    font-size: 20px;
    font-weight: bold;
}

#container {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
}

#content {
    flex: 1 1 auto;
    overflow-y: auto;
    min-height: 0px;
}

.dashboard-btn-hangup-wrapper{
    display: flex;
    justify-content: center;
    align-items: center;
}

.dashboard-btn-hangup{
    width: 40px;
    height: 40px;
    padding: 2px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

}

.dashboard-btn-hangup:hover{
    background: hsla(217,7.6%,33.5%,0.6);
    color:hsl(210,2.9%,86.7%);
}

.dashboard-panel-sandwich-container{
    display: none;
    justify-content: center;
    align-items: center;
    margin-left: 10px;
}

.dashboard-panel-sandwich-wrapper{
    display: flex;
    flex-wrap: wrap;
    width: 18px;
    height: 15px;
    cursor: pointer;
}

.dashboard-panel-sandwich-wrapper  span{
    width: 50%;
    background: hsl(0,calc(var(--saturation-factor, 1)*0%),100%);
    border-radius: 9px 0 0 9px;
    transform: scale(1.1);
    height: 3px;
    border: none;
}
.mobile-wrapper{
    display: flex;
    overflow: hidden;
    flex-direction: column;
    flex: 1 1;
}

@media only screen and (max-width: 768px) {
    .dashboard-sidebar{
        width: 100%;
    }
    .dashboard-panel-sandwich-wrapper{
        display: flex;
    }
    .dashboard-panel-sandwich-container{
        display: flex;
    }
}
