.information-wrapper{
	top: 0;
	left: 0;
	position: fixed;
	width: 100%;
	height: 100%;
	z-index: 1;
	color: white;
}

.information-container{
	position: relative;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.information-backdrop{
	position: absolute;
	background: rgba(0, 0, 0, 0.5);
	width: 100%;
	height: 100%;
}

.information-modal-wrapper{
	width: max-content;
	background: #36393F;
	z-index: 2;
	border-radius: 5px;
	padding: 50px;
	padding-top: 20px;
	box-shadow: 3px 3px 3px black;

}

.information-modal-btnClose-wrapper{
	display: flex;
	justify-content: flex-end;
}

.information-modal-btnClose{
	cursor: pointer;
	height: 40px;
	width: 40px;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 20px;
}

.information-modal-header{
	display: flex;
	justify-content: center;
	font-size: 28px;

}

.information-modal-body{
	flex: 1 1;
	display: flex;
	flex-direction: column;
	align-items: center;
	display: flex;
	flex-direction: column;
	gap: 10px;
}

.information-auth-name{
	font-size: 24px;
}

.information-auth-wrapper{}

.information-auth-name{
	text-decoration:underline;
}

.information-auth-email-wrapper{
	display: flex;
	gap: 5px;
}
.information-auth-password-wrapper{
	display: flex;
	gap: 5px;
}


.information-footer{
	margin-top: 20px;
	text-decoration: underline;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
}

.information-modal-btnClose:hover{
	background: rgba(255, 255, 255, 0.2);
	border-radius: 100%;
}