*{
    padding: 0;
    margin: 0;
}

.panelWrapper,.panelWrapper-active{
    height:100%;
    width: 100%;
    background-color: #ccc;
    display: flex;
    flex: auto 1;
    flex-direction: row;
    transition: ease-in-out 0.3s;
    position: fixed;

}

.panelWrapper{
    transform: scale(.93);
    opacity: 0;

}

.panelWrapper-active{
    transform: scale(1);
    opacity: 1;
}

.panel-mobile-wrapper{
    flex: 1 1;

}

@media only screen and (max-width: 768px) {
    .panel-mobile-wrapper{
        position: absolute;
        display: none;
        overflow: hidden;
        flex-direction: column;
        height: 100%;
        left: 0;
        top: 0;
    }
    .panel-mobile-wrapper.show{
        position: absolute;
        display: flex;
        overflow: hidden;
        flex-direction: column;
        flex: 1 1;
        width: 100%;
    }   
}