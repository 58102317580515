.modal-close-icon{
	position: absolute;
	right: 15px;
	top: 15px;
	color:#4f5660;
	cursor: pointer;
}

.modal-close-icon:hover{
	color: #2e3338;
}

.modal-container{
	position: fixed;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	pointer-events:none;
}

.modal-wrapper{
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	pointer-events: none;
	display: flex;
	justify-content: center;
	align-items: center;
	transition: all 0.2s ease-in-out;
	background: transparent;
}
.modal-wrapper.active{
	pointer-events: all;
	opacity: 0.85;
	background: hsl(0,0% , 0%);
}

.mymodal{
	position: absolute;
	background: #fff;
	width: 440px;
	border-radius: 4px;
	transition: all 0.2s ease-in-out;
	transform: scale(0);
	opacity: 0;
	overflow: hidden;

}

.mymodal.active{
	pointer-events: all;
	opacity: 1;
	transform: scale(1);
	
}

.modal-secondRow{
	background: #fff;
	width: 100%;
	padding: 20px;
	padding-top: 6px;
}

.mymodal-header{
	min-height: 75px;
	display: flex;
	justify-content: center;
	align-items: flex-end;
	font-weight: 700;
	color: #060607;
	font-size: 24px;
}

.modal-subheader{
	height: auto;
	display: flex;
	justify-content: center;
	align-items: flex-start;
	text-align: center;
	padding: 0 15px;
	font-size: 15.5px;
	font-weight: 300;
}

.modal-secondRow-header{
	height: 50px;
	display: flex;
	justify-content: center;
	align-items: flex-end;
	font-weight: 400;
	color: #060607;
	font-size: 24px;
}

.modal-secondRow-button-wrapper{
	display: flex;
	justify-content: center;
	align-items: center;
}
.modal-secondRow-button{
	outline: none;
	border: none;
	border-radius: 3px;
	background: #6a7480;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	color: #fff;
	height: 45px;
	padding: 20px;
}

.modal-create-buttons{
	margin-top: 15px;
	margin-bottom: 10px;
	padding: 0 8px 8px 16px;
	overflow-y: scroll;
	height: 330px;
	display: flex;
	flex-direction: column;
	row-gap: 10px;
	
}
.modal-create-buttons::-webkit-scrollbar{
	width: 8px;
	height: 8px;
}

.modal-create-buttons::-webkit-scrollbar-thumb{
	background-clip: padding-box;
	border: 2px solid transparent;
	border-radius: 4px;
	background-color: rgba(79,84,92,0.3);
	min-height: 40px;
}

.modal-create-buttons::-webkit-scrollbar-track{
	border-color:transparent ;
	background-color: transparent;
	border: 2px solid transparent;
	
}


.modal-create-button{
	border-radius: 8px;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	border: 1px solid rgba(106, 116,128,0.24);
	background: transparent;
	padding: 10px;
	
}

.modal-create-button-icon{
	display: flex;
	flex:1 1;
	justify-content: flex-end;
}

.modal-create-button-icon svg{
	transform: rotate(-90deg);
}

.modal-create-button:hover{
	background: rgba(106, 116,128,0.16);
}


.modal-create-button-content{
	display: flex;
	justify-content: center;
	align-items: center;
	color: #060607;
	height: 50px;
	flex: 1 1;
	column-gap: 10px;
}

.modal-create-button-content-text{
	font-weight: 700;
	font-size: 16px;
	line-height: 20px;
}

.modal-back-button{
	border: none;
	display: flex;
	justify-content: center;
	align-items: center;
	background: transparent;
	
}

.modal-upload-button-wrapper{
	border: none;
	display: flex;
	justify-content: center;
	align-items: center;
	background: transparent;
	position: relative;
	width: 100%;
	height: 80px;
}

.modal-upload-button-wrapper img{
	height: 80px;
	width: 80px;
}
.modal-upload-button{
	width: 80px;
	height: 100%;
	background: transparent;
	cursor: pointer;
	opacity: 0;
	
}

.modal-input-wrapper{
	margin-top: 15px;
	display: flex;
	flex-direction: column;
	height: 100%;
	width: 100%;
	border: none;
	background: transparent;
	padding: 0 20px;

	
}

.modal-input-label{
	font-size: 14px;
	font-weight: 700;
	color: #4f5660;
	margin-bottom: 8px;
	line-height: 16px;
	font-weight: 600;
	text-transform: uppercase;
}

.modal-input{
	border: none;
	outline: none;
	width: 100%;
	height: 40px;
	border: none;
	background: transparent;
	color: #2e3338;
	background-color: #e3e3e8;
	padding: 10px;
	border-radius: 3px;
}

.modal-term{
	display: flex;
	justify-content: center;
	color: #060607;
	font-size: 12px;
	font-weight: 500;
	margin-top: 10px;
	color: #5e6772;
	font-size: 11px;
	line-height: 16px;
	margin-top: 8px;
	padding: 5px 20px 15px 20px;
}
@media only screen and (max-width: 440px) {
	.mymodal{
		height: 100%;
		width: 100%;
	}
	.modal-wrapper.active{
		opacity: 1;
	}
}