.navbar-wrapper{
    width: 72px;
    background-color: #202225;
    overflow-y: scroll;
    overflow-x: hidden;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    display: flex;
    flex-direction: column;
}

.navbar-wrapper::-webkit-scrollbar {
    display: none;
}

.navbar-list-item{
    width: 72px;
    height: 48px;
    padding: 0;
    margin-top: 8px;
    margin-bottom: 8px;
    list-style: none;
    display: flex;
    align-items: center;
    position: relative;
}

.navbar-list-item span{
    width: 0px;
    height: 0px;
    position: absolute;
}

.navbarCircle{
    width:48px;
    height:48px;
    border-radius: 24px;
    background-color: #36393F;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color: #dcddde;
    font-weight: 500;
    transition: all 0.1s;
    overflow: hidden;
}

.navbarCircle:hover{
    border-radius: 17px;
    background-color: #5865F2;
    transition: all 0.1s;
    color: #fff;

}

.navbarCircle:hover ~ span{
    width: 8px;
    height: 20px;
    border-radius: 0 4px 4px 0;
    margin-left: -4px;
    background-color: #dcddde;
    margin-right: 10px;
    transition: all 0.3s;
}

.navbarCircle.active{
    color: #fff;
    background-color: #5865F2;
    border-radius: 17px;
}

.navbarCircle.active ~ span{
    width: 8px;
    height: 40px;
    border-radius: 0 4px 4px 0;
    margin-left: -4px;
    background-color: #dcddde;
    margin-right: 10px;
    transition: all 0.3s;
}

.navbarLine{
    width:30px;
    height:2px;
    margin: 0 auto;
    justify-content: center;
    align-items: center;
    display: flex;
}

.navbarLine span{
    width:48px;
    height:2px;
    background-color: hsla(0,0%,100%,0.06);

}

.navbarUserCircle{
    width:48px;
    height:48px;
    border-radius: 24px;
    background-color: #36393F;
    margin-top: 10px;
    cursor: pointer;
}
.navbarUserCircle:hover{
    border-radius: 17px;
    transition: all 0.5s ease-in-out;

}

.navbarAddUser{
    width:48px;
    height:48px;
    border-radius: 24px;
    margin: auto;

    background-color: #36393F;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.navbarAddUser a{
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.navbarAddUser:hover {
    border-radius: 17px;
    background-color: #3ba55d;
    transition: all 0.1s ease-in-out;

}

.navbarAddUser:hover ~ span{
    width: 8px;
    height: 20px;
    border-radius: 0 4px 4px 0;
    margin-left: -4px;
    background-color: #dcddde;
    margin-right: 10px;
    transition: all 0.3s;
}

.navbarAddUser div{
    width:15px;
    height:2px;
    background-color: #3ba55d;
    position: absolute;
}

.navbarAddUser:hover div{
    background-color: #fff;
    transition: all 0.5s ease-in-out;


}
.navbarAddUser:hover svg{
    fill: #fff;
    transition: all 0.5s ease-in-out;
}

.navbarAddUser div:first-child{
    height: 15px;
    width: 2px;
}

.navbarAddUser svg{
    fill: #3ba55d;
}

.dashboard-navbar-search-button-wrapper{
    width: 100%;
    display: flex;
    justify-content: center;
    box-shadow:0 1px 0 rgba(4,4,5,0.2),0 1.5px 0 rgba(6,6,7,0.05),0 2px 0 rgba(4,4,5,0.05);
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    padding: 0 10px;
    height: 48px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.dashboard-navbar-search-button{
    width:100%;
    height: 28px;
    overflow: hidden;
    background-color: #202225;
    border-radius: 4px;
    border: none;
    color: #72767d;
    text-align: left;
    padding-left: 5px;
    
    text-align: left;
    text-overflow: ellipsis;
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    white-space: nowrap;
}

.dashboard-navbar-server-name{
    display:flex;
    align-items:center;
    color:hsl(0,0%,100%);
    font-size: 15px;
    line-height: 20px;
    font-weight: 500;
    text-overflow: ellipsis;
    overflow: hidden;
    flex:1;
    margin: 0;
    padding: 12px 16px;
    transition: all 0.1s ease-in-out;
    cursor: pointer;

}

.dashboard-navbar-server-name:hover{
    background:hsla(217,7.6%,33.5%,0.4);
}
