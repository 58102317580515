.header-chat-search-input-wrapper{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    justify-content: space-between;

}

.header-chat-search-input-wrapper input{
    width: 150px;
    height: 24px;
    border: none;
    color: #fff;
    font-size: 16px;
    padding: 0 10px;
    outline: none;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='40' height='40' viewBox='0 -2 30 30'  fillRule='evenodd'%3E%3Cpath d='M16.036 18.455l2.404-2.405 5.586 5.587-2.404 2.404zM8.5 2C12.1 2 15 4.9 15 8.5S12.1 15 8.5 15 2 12.1 2 8.5 4.9 2 8.5 2zm0-2C3.8 0 0 3.8 0 8.5S3.8 17 8.5 17 17 13.2 17 8.5 13.2 0 8.5 0zM15 16a1 1 0 1 1 2 0 1 1 0 1 1-2 0'%3E%3C/path%3E%3C/svg%3E") right / contain no-repeat ;
    background-color: #202225;
    padding-right:30px;
    border-radius: 5px;
    transition: ease-in-out .2s;
}
.header-chat-search-input-wrapper input:focus{
    width: 240px;
}

.header-friend-right-wrapper{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.header-friend-right-wrapper div{
    margin: 0px 8px;
}
.header-friend-right-wrapper div svg{
    color: #b9bbbe;

}
.header-friend-right-wrapper div:hover svg{
    color: #dcddde;
}

.header-friend-name{
    display:flex;
    flex:1 auto;
    color: white;
    margin:0px 8px
}