.landing-navbar-wrapper{
    padding: 0 85px;
    width: 100%;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    
}

.landing-navbar{
    display: flex;
    width: 100%;
    max-width: 1260px;
}

.landing-navbar-logo-wrapper{
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.landing-navbar-center-wrapper{
    color: white;
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
}
.landing-navbar-login-wrapper{
    display: flex;
    align-items: center;
    justify-content: center;
}
.landing-navbar-center-wrapper span{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    margin-inline: 10px;
    cursor: pointer;
}

.landing-navbar-center-wrapper span:hover{
    text-decoration:underline
}

.landing-buttons-wrapper{
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 24px;
    padding-inline: 70px;
    column-gap: 24px;
}

.landing-navbar-login-button{
    background-color: #fff;
    border-radius: 40px;
    border: none;
    font-size: 14px;
    padding: 10px 20px;
    text-align: center;
    cursor: pointer;
    font-size: 14px;
    padding: 7px 16px;
}

.landing-01{
    background-image: url(/public/assets/img/landing-1.svg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding:120px 40px;
    color: white;
}

.landing-wrapper{
    width: 100%;
    padding: 120px 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
}

.landing-wrapper-01{
    padding: 120px 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.landing-wrapper-01 button{
    background-color: #5865f2;
    color: white;
    margin-top: 40px;
    border-radius: 40px;
    border: none;
    font-size: 20px;
    padding: 16px 32px;
    text-align: center;
    cursor: pointer;
}
.landing-wrapper-01 h4{
    font-weight: 600;
    font-size: 32px;
    font-family:"Ginto Normal","Whitney","Helvetica Neue","Helvetica","Arial",sans-serif
}

.landing-01-download-button{
    background-color: #fff;
    color:#000;
    border-radius: 40px;
    border: none;
    font-size: 20px;
    padding: 16px 32px;
    text-align: center;
    cursor: pointer;
    font-weight: 500;
    width: max-content;

}

.landing-01-open-button{
    background-color: #23272a;
    color:#fff;
    border-radius: 40px;
    border: none;
    font-size: 18px;
    padding: 16px 32px;
    text-align: center;
    cursor: pointer;
    font-weight: 500;
    width: max-content;

}

.landing-01-header{
    font-size: 64px;
    font-weight: 800;
    text-align: center;
    line-height: 95%;
}

.landing-01-subheader{
    margin-top: 40px;
    text-align: center;
    line-height: 1.625;
    font-size: clamp(16px,2vw,20px);
    margin-bottom: 8px;
}
.footer-wrapper{
    background-color: #23272a;
    color: #fff;
    text-align: center;
    font-size: 12px;
    width: 100%;
    padding: 80px 0 64px 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.footer-first-wrapper{
    padding: 0 40px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 40px;
    
}

.footer-links-01{
    width: 250px;
}

.footer-links{
    width: 175px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 10px;
}
.footer-links-01-media{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.footer-links span{
    margin-bottom: 8px;
    font-size: 16px;
    line-height: 24px;
    cursor: pointer;
}

.footer-links span:hover{
    text-decoration: underline;
}

.footer-links h5{
    color: #5865f2;
}

.footer-second-wrapper{
    padding: 0 40px;
}

.footer-second-wrapper-01{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.footer-divider{
    border-bottom: 1px solid #5865f2;
    width: 100%;
    margin-bottom: 32px;
}

.footer-second-wrapper-01 button{
    background-color: #5865f2;
    color: #fff;
    border: none;
    border-radius: 40px;
    font-size: 14px;
    padding: 7px 16px;
    font-weight: 600;
    cursor: pointer;
}

.landing-content-picture{

}

@media only screen and (max-width: 1050px){
    .landing-navbar-wrapper{
        padding: 24px;
    }
    .landing-navbar{
        justify-content: space-between;
        
    }
    .landing-navbar-center-wrapper{
        display: none;
    }

    .landing{
        flex-direction: column;
    }
    .landing-buttons-wrapper{
        flex-direction: column;
        row-gap: 10px;
    }
}

.landing{
    text-align: left;
    max-width: 1260px;
    display: flex;
    column-gap: 50px;
}

.landing-content{
    width: 380px;
}

.landing-content h2{
    font-size: 48px;
    font-weight: 700;
}

.landing-content span{
    font-size: clamp(16px,2vw,20px);
}


@media only screen and (max-width: 610px){

}

@media only screen and (max-width: 900px){
    .landing-content{
        width: 500px;
    }
    .landing-content-picture{
        width: 100%;
        height: auto;
    }
    .footer-first-wrapper{
        flex-wrap: wrap;
    }
    .footer-links-01{
        width: 40vw;
        margin-right: 60px;
    }
    .landing-wrapper-01{
        padding: 10px 40px 60px 40px;
    }

}

@media only screen and (max-width: 500px){
    .landing-content{
        width: 100%;
    }
    .landing-01-header{
        padding: 0 10px;
    }
    .landing-01-subheader{
        padding: 0 20px;
        width: 100%;
    }
    .footer-first-wrapper{
        row-gap: 24px;
        column-gap: 24px;
    }
    .footer-links-01{
        width: 100%;
        margin:0;
        margin-bottom: 10px;
    }
    .footer-links{
        width:100px
    }
    .footer-wrapper{
        padding: 20px 0px;
    }
}

