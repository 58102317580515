.settings-out{
    background:"#36393F";
    width: 100%;
    height: 100%;
    position:fixed;
    top:0;
    left:0;
    z-index: -1;
    opacity: 0;
    transition: 0.3s ease;
    transform: scale(1.2);
}

.settings-out-active{
    background:#36393F;
    width: 100%;
    height: 100%;
    position:fixed;
    top:0;
    left:0;
    opacity: 1;
    z-index: 1;
    transition: 0.3s ease;
    transform: scale(1);

}

.settings-wrapper{
    width:100%;
    height:100%;
    display:flex;
    transition: 0.3s ease;

}

.settings-wrapper-active{
    width:100%;
    height:100%;
    display:flex;
    transition: ease 0.3s;
}

.settings-primary-wrapper{
    background:#2F3136;
    flex:1;
    display: flex;
    justify-content: flex-end;
    padding-right: 10px;
    color: #b9bbbe;
    line-height: 14px;
    font-size: 16px;
    overflow: hidden;
    overflow-y: scroll;
}

.settings-primary-wrapper::-webkit-scrollbar {
    width: 8px;
    background-color: #2e3338;
    border-radius: 5px;

}
.settings-primary-wrapper::-webkit-scrollbar-track {
    border-radius: 10px;
}
   
.settings-primary-wrapper::-webkit-scrollbar-thumb {
    background: #202225; 
    border-radius: 10px;
}

.settings-secondary-wrapper{
    flex:1 0 218px;
    background:#36393F;
}

.settings-divider{
    width:100%;
    height:1px;
    background:rgba(79,84,92,0.48);
    margin: 8px 0;
}

.settings-primary{
    padding: 60px 6px 60px 20px;
}

.settings-primary-item-head{
    font-size: 12px;
    font-weight: 700;
    line-height: 10px;
    text-transform: uppercase;
    padding: 8px 10px ;
}

.settings-primary-item-item-active{
    font-weight: 500;
    cursor: pointer;
    border-radius: 4px;
    margin-top: 2.5px;
    padding: 8px 10px ;
    color: #fff;
    background: #42464D;
    display: flex;
    justify-content: space-between;

}

.settings-primary-item-item:hover{
    cursor: pointer;
    background: #3C3F45;
}

.settings-primary-item-item{
    font-weight: 500;
    cursor: pointer;
    border-radius: 4px;
    margin-top: 2.5px;
    padding: 8px 10px ;
    display: flex;
    justify-content: space-between;
    
}

.settings-primary-item-item-active:hover{
    background: #3C3F45;
}

.settings-social{
    margin: 10px 0px;
}
.settings-social-icon{
    width: 20px;
    height: 20px;
    margin-right: 10px;
    color: #b9bbbe;
    cursor: pointer;
}

.settings-social-icon:hover{
    color: #fff;
}

.settings-close-button{
    color: #b9bbbe;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.settings-close-icon{
    width: 40px;
    height: 40px;
    cursor: pointer;
}

@media only screen and  (max-width : 768px){
    .settings-primary-wrapper{
        justify-content: center;
    }
    .settings-secondary-wrapper{
        flex: inherit;
    }
    .settings-close-button{
        position: absolute;
        right: 20px;
        top: 20px;
    }
    .settings-wrapper-active{
        position: relative;
    }
    .settings-close-button div{
        display: none;
    }
}